import { deliveryTrackingApiDefinition } from 'api/definitions/delivery-tracking';
import { GET_DELIVERY_STATUS } from './types';

export const getDeliveryStatus = (orderId: string) => (dispatch: WtrDispatch) => {
  return dispatch({
    types: GET_DELIVERY_STATUS.triplet,
    orderId, // this is sent to the reducer
    apiCall: deliveryTrackingApiDefinition.get({
      orderId,
      iHandleStatusCodes: [400, 404, 406, 500, 502, 503, 504], // Handle generic errors but allow 401/403 to redirect to login
    }),
  });
};
