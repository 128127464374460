import { apiCallback } from 'api';
import { ApiCallArgs } from 'api/types';
import { TIMEOUT_CONFIG } from 'constants/checkout-api-timeout';
import { DeliveryStatus } from 'constants/deliveryStatus';

export type DeliveryStatusResponse = {
  customerOrderId: string;
  status: DeliveryStatus;
  etaStartDateTime?: string;
  etaEndDateTime?: string;
  wismoEtaStartDateTime?: string;
  wismoEtaEndDateTime?: string;
  runningLate: boolean;
  driverName?: string;
};

const get = {
  service: 'delivery-tracking-orchestration',
  endpoint: 'delivery-statuses/${orderId}',
  verbs: ['get'],
  defaultVersion: 1,
  timeout: TIMEOUT_CONFIG,
};

const getDeliveryStatusForOrder: (
  args: { orderId: string } & ApiCallArgs,
) => WtrApiCall<DeliveryStatusResponse> = apiCallback(get, 'get');

export const deliveryTrackingApiDefinition = {
  get: getDeliveryStatusForOrder,
};
