import deliveryStatusType, { DeliveryStatus } from 'constants/deliveryStatus';
import { useRef, useEffect } from 'react';
import { useWtrDispatch, useWtrSelector } from 'redux/hooks';
import { getDeliveryStatusByOrderId } from 'redux/modules/delivery-tracking/selectors';
import { isEligibleForDeliveryTracking } from 'redux/modules/order/selectors/is-eligible-for-delivery-tracking';
import { getDeliveryStatus } from 'redux/modules/orders/actions/get-delivery-status';
import { getStatus, getSlotByOrderId } from 'redux/modules/orders/selectors/get-order';

function isOrderFinished(deliveryTrackingStatus: DeliveryStatus | undefined): boolean {
  return (
    deliveryTrackingStatus === deliveryStatusType.DELIVERED ||
    deliveryTrackingStatus === deliveryStatusType.CANCELLED ||
    deliveryTrackingStatus === deliveryStatusType.MISSED
  );
}

function clearCurrentInterval(ref: React.MutableRefObject<NodeJS.Timeout | null>) {
  if (ref.current) {
    clearInterval(ref.current);
    // eslint-disable-next-line no-param-reassign
    ref.current = null;
  }
}

function useDeliveryTrackingPoll(orderId: string) {
  const intervalMs = 300000; // 5 minutes;
  const dispatch = useWtrDispatch();
  const status = useWtrSelector((state: WtrState) => getStatus(state, orderId));
  const deliveryStatus = useWtrSelector((state: WtrState) =>
    getDeliveryStatusByOrderId(state, orderId),
  );
  const orderSlot = useWtrSelector((state: WtrState) => getSlotByOrderId(state, orderId));
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (
      isEligibleForDeliveryTracking(status, orderSlot) &&
      !isOrderFinished(deliveryStatus) &&
      !intervalRef.current
    ) {
      intervalRef.current = setInterval(() => {
        dispatch(getDeliveryStatus(orderId)).then(result => {
          if (isOrderFinished(result?.status)) {
            clearCurrentInterval(intervalRef);
          }
        });
      }, intervalMs);
    }

    return () => {
      clearCurrentInterval(intervalRef);
    };
  }, [dispatch, orderId, orderSlot, status, deliveryStatus]);
}

export default useDeliveryTrackingPoll;
